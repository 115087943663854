<template>
    <div class="k-h-full k-d-flex k-flex-col">
        <KGrid ref="theGrid" :class="['theGrid', gridWidthPercentageClass, gridGroupedClass]" :sortable=" sortable"
               :filterable="filterable" :groupable="groupable" :reorderable="reorderable" :resizable="resizable"
               :pageable="gridPageable" :data-items="dataResult" :skip="skip" :take="take" :sort="sort" :group="group"
               :filter="filter" :columns="columns" width="800" :expand-field="'expanded'"
               @sortchange="sortChangeHandler"
               @datastatechange="dataStateChange" @expandchange="expandChange" @columnreorder="columnReorder"
               @columnresize="columnResize" :loader="loader" v-show="hasError === false">

            <template v-slot:headerTemplate="{ props }">
                <div :class="'k-column-title cellClass'" :title="props.title">{{ props.title }}</div>
            </template>

            <template v-slot:encounterLinkTemplate="{ props }">
                <td :colspan="props.colspan" :class="props.class + ' k-grid-edit-command'" :style="props.style">
                    <div :class="'cellClass'" :title="props.dataItem.EncounterNumber">
                        <EncounterLink :serviceProviderId="props.dataItem.ServiceProviderId"
                                       :clientId="props.dataItem.ClientId" :facilityId="props.dataItem.FacilityId"
                                       :workpoolId="props.dataItem.WorkPoolId" :encounterStage="props.dataItem.Stage"
                                       :encounterId="props.dataItem.EncounterId" :encounterNumber="props.dataItem.EncounterNumber"
                                       :linkTarget="'myEncounterDetail'"></EncounterLink>
                    </div>
                </td>
            </template>

            <template v-slot:cellLeftAlign="{ props }">
                <td :colspan="props.colspan" :class="props.class + ' k-grid-edit-command'" :style="props.style">
                    <div :class="'cellClass'" :title="props.dataItem[props.field]">
                        {{ props.dataItem[props.field] }}
                    </div>
                </td>
            </template>

            <template v-slot:cellRightAlign="{ props }">
                <td :colspan="props.colspan" :class="props.class + ' k-grid-edit-command'" :style="props.style">
                    <div :class="'cellClass'" :title="props.dataItem[props.field]">

                        <div :class="'cellRight'">
                            {{ props.dataItem[props.field] }}
                        </div>
                    </div>
                </td>
            </template>

            <template v-slot:cellCurrency="{ props }">
                <td :colspan="props.colspan" :class="props.class + ' k-grid-edit-command'" :style="props.style">
                    <div :class="'cellClass'" :title="currencyDisplay(props.dataItem[props.field]) ">
                        <div :class="'cellRight'">
                            {{ currencyDisplay(props.dataItem[props.field]) }}
                        </div>
                    </div>
                </td>
            </template>

            <template v-slot:cellPercentage="{ props }">
                <td :colspan="props.colspan" :class="props.class + ' k-grid-edit-command'" :style="props.style">
                    <div :class="'cellClass'" :title="percentageDisplay(props.dataItem[props.field]) ">
                        <div :class="'cellRight'">
                            {{ percentageDisplay(props.dataItem[props.field]) }}
                        </div>
                    </div>
                </td>
            </template>

            <template v-slot:myGridColumnMenuTemplate="{ props }">
                <div>
                    <GridColumnMenu :column="props.column" :filterable="props.filterable" :filter="props.filter"
                                    :sortable="props.sortable" :sort="props.sort" :columns="columns"
                                    :defaultColumns="defaultColumns" :locked="isColumnLocked(props.column.field)"
                                    @sortchange="(e) => props.onSortchange(e)" @lockchange="(e) => lockChange(e, props.column)"
                                    @visibilitychange="(e) => visibilityChange(e, props.column)"
                                    @filterchange="(newDescriptor, e) => filterChange(newDescriptor, e)"
                                    @closemenu="(e) => props.onClosemenu(e)" @contentfocus="(e) => props.onContentfocus(e)"
                                    @columnssubmit="onColumnsSubmit" />
                </div>
            </template>
        </KGrid>
        <div class="k-h-full k-w-full k-d-flex k-justify-content-center k-align-items-center"
             v-show="hasError === true">
            <i class="fa-regular fa-triangle-exclamation widget-error-icon" :title="errorText"></i>
        </div>
    </div>
</template>

<style>
    .headerBar {
        background-color: var(--purple-100);
        padding: 7px 20px;
        display: flex;
    }

    .headerBarLeft {
        width: 50%;
        display: flex;
        align-content: center;
        justify-content: flex-start;
    }

    .headerBarRight {
        width: 50%;
        display: flex;
        align-content: center;
        justify-content: flex-end;
    }

    .theGrid {
        height: 100%;
    }

    .GridWidthPercentage {
        width: 100% !important;
    }

        .GridWidthPercentage .k-grid-header-table,
        .GridWidthPercentage .k-grid-table {
            width: 100% !important;
        }

        .GridWidthPercentage .k-grid-header col {
            width: 20%;
        }

        .GridWidthPercentage .k-grid-table col {
            width: 20%;
        }

        /* This is for the blank group cells. */
        .GridWidthPercentage.GridGrouped .k-grid-header col:nth-of-type(1) {
            width: 32px !important;
        }

        .GridWidthPercentage.GridGrouped .k-grid-table col:nth-of-type(1) {
            width: 32px !important;
        }

    .cellClass {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: clip;
    }

    .cellRight {
        text-align: right;
    }
</style>

<script setup>
    import { ref, defineProps, defineExpose, computed, onMounted, watch } from 'vue';
    import { Grid as KGrid, filterGroupByField } from '@progress/kendo-vue-grid';
    import { process } from '@progress/kendo-data-query';
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { default as GridColumnMenu } from '../common/GridColumnMenu.vue';
    import { default as EncounterLink } from '../common/EncounterLink.vue'
    import { useCommonStore } from '../../store/useCommonStore'
    import { fetchAPI } from '../../modules/fetchAPI';
    import moment from 'moment';
    import * as encounterGridConfiguration from '../../modules/encounterGridConfiguration';

    // Props
    const props = defineProps([
        'mode',
        'widgetId',
        'tileId',
        'timePeriod',
        'startDate',
        'endDate',
        'isDetail'
    ]);

    //Vizient Fields
    const vizientColumns = [
        'RelativeExpectedDirectCost', 'OriginalRelativeExpectedDirectCost',
        'RelativeExpectedLOS', 'OriginalRelativeExpectedLOS',
        'RelativeExpectedMortality', 'OriginalRelativeExpectedMortality'
    ];

    const operatorMapping = {
        'isempty': 'isnull',
        'isnotempty': 'isnotnull',
        'isnull': 'isempty',
        'isnotnull': 'isnotempty'
    };

    // Data
    const commonStore = useCommonStore();
    const isCoder = ref(commonStore.isCoder);

    const theGrid = ref(null);
    const widget = ref(null);
    var dataItems;
    var gridConfiguration;
    const loader = ref(false);
    let gridPageable = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [5, 10, 15, 20],
        previousNext: true,
    };

    const skip = ref(0);
    const take = ref(15);
    const sort = ref([]);
    const group = ref([]);
    const filter = ref({ logic: 'and', filters: [] });
    const dataResult = ref({ data: [], total: 0 });

    let sortable = {
        allowUnsort: true,
        mode: 'single' //multiple
    }
    const filterable = ref(false); // This sets the filter row only. Filtering in the column menu is still available if false.
    const groupable = ref(false); // Start false to avoid initially showing the column.
    const reorderable = ref(true);
    const resizable = ref(true);
    const hasError = ref(false);
    const errorText = ref('');

    const defaultColumns = ref(null);
    const columns = ref(null);
    const resetColumns = ref(null);
    const gridWidthPercentage = ref(false);
    const defaultSort = ref('');

    const widgetName = computed(() => {
        if (widget.value === null) { return ''; }
        return widget.value.WidgetName;
    });

    const gridWidthPercentageClass = computed(() => gridWidthPercentage.value ? 'GridWidthPercentage' : '');
    const gridGroupedClass = computed(() => group.value.length > 0 ? 'GridGrouped' : '');

    // Lifecycle Methods
    onMounted(() => {

        loader.value = true;

        // Get the default columns set up.
        defaultColumns.value = encounterGridConfiguration.SetEncounterGridDefaultColumns(commonStore);

        // Get any other grid configuration necessary.
        if (encounterGridConfiguration.isGridFullWidth(commonStore)) {
            gridWidthPercentage.value = true;
        }

        // Use the default columns to set up column handling.
        columns.value = structuredClone(defaultColumns.value);
        resetColumns.value = structuredClone(defaultColumns.value);

        // Set up the default sort.
        defaultSort.value = encounterGridConfiguration.SetEncounterGridDefaultSort(commonStore);

        let request = {
            WidgetId: props.widgetId,
            TileId: props.tileId,
            TimePeriod: props.timePeriod,
            StartDate: props.startDate,
            EndDate: props.endDate,
            IsDetail: true,
            SortBy: defaultSort.value,
            DataSourceForeignId: '0',
            Connection: "",
            UserId: "",
            ResultDataType: "Table",
            RefreshData: true,
        };

        fetchAPI("api/Widget/GetWidgetResult", request)
            .then(response => {
                if (!response.ok) {
                    hasError.value = true;
                    errorText.value = 'Results failed to load. Please contact our Support Team.';
                    throw new Error(errorText.value);
                }
                return response.json();
            })
            .then(data => {
                if (data.result === '') {
                    hasError.value = true;
                    errorText.value = `Results failed to load. Please contact our Support Team. (Error ID: ${data.logMessageId})`
                }
                else {
                    widget.value = JSON.parse(data.widget);
                    dataItems = JSON.parse(data.result);
                    gridConfiguration = JSON.parse(data.gridConfiguration);
                    setGridConfiguration();
                }
            })
            .catch(error => {
                hasError.value = true;
                errorText.value = 'Results failed to load. Please contact our Support Team.'
                console.error('There was an error!', error)
            });

        loader.value = false;
    });

    // Define a custom sorting function
    const compareScores = function (score1, score2) {
        var regex = new RegExp(/^\D+|\D+$/g);
        score1 = score1?.replace(regex, "");
        score2 = score2?.replace(regex, "");

        if (score1 === undefined) score1 = 0;
        if (score2 === undefined) score2 = 0;
        if (score1 > score2) {
            return 1;
        } else if (score1 < score2) {
            return -1;
        }

        return 0;
    }

    const customCompare = (a, b, sort) => {
        const correction = sort.dir == 'asc' ? 1 : -1;
        return correction * compareScores(a[sort.field], b[sort.field]);
    };

    const getSortValueViaVizientCheck = function (sortArray) {
        return sortArray.map((sortItem) => {
            if (vizientColumns.includes(sortItem.field)) {
                return {
                    ...sortItem,
                    compare: (a, b) => customCompare(a, b, sortItem),
                };
            }
            return sortItem;
        });
    }

    // Kendo UI for Vue currently (7.29.2024) does not support a custom compare sort at the column level, so we will add
    //  our custom compare sort using the Grid sortChange event.
    const sortChangeHandler = function (e) {
        // This function will handle (2) different sort scenarios. If the User clicks the column header, the (e) parameter will be an [event] object that has
        //  a child [sort]. If the User chooses to sort via the kabob per column header, the (e) parameter will only be the [sort] object.
        let sortArray = e.event ? e.sort : e;
        sort.value = getSortValueViaVizientCheck(sortArray);

        dataResult.value = processDataItems();
    }

    const saveGridConfiguration = function () {

        const gridState = {};
        gridState.sortable = sortable.value;
        gridState.filterable = filterable.value;
        gridState.groupable = groupable.value;
        gridState.skip = 0; // Do not save page number value. To save, use skip.value;
        gridState.take = take.value;
        gridState.group = group.value;
        gridState.sort = sort.value;
        gridState.filter = filter.value;
        gridState.columns = columns.value;

        let request = {
            WidgetId: Number(props.widgetId),
            TileId: Number(props.tileId),
            GridConfiguration: JSON.stringify(gridState)
        };

        fetchAPI("api/Widget/SaveGridConfiguration", request)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
            })
            .catch(error => console.error('There was an error saving the grid configuration.', error));
    }

    const setGridConfiguration = function () {
        if (Object.keys(gridConfiguration).length !== 0) {

            const gridState = gridConfiguration;

            sortable.value = gridState.sortable;
            filterable.value = gridState.filterable;
            groupable.value = gridState.groupable;
            skip.value = gridState.skip;
            take.value = gridState.take;
            group.value = gridState.group;
            sort.value = getSortValueViaVizientCheck(gridState.sort);
            filter.value = gridState.filter;
            columns.value = gridState.columns;
        }
        else {
            setDefaultState();
        }
        updateState();
    }

    const resetGridConfiguration = function () {
        setDefaultState();
        saveGridConfiguration();
        updateState();
    }

    const setDefaultState = function () {
        sortable.value = true;
        filterable.value = false; // This is for the filter row, not the column menu filtering.
        groupable.value = (props.mode === 'detail' ? true : false);
        skip.value = 0;
        take.value = (props.mode === 'detail' ? 15 : 10);
        group.value = [];
        sort.value = [];
        filter.value = { logic: "and", filters: [] };
        columns.value = structuredClone(resetColumns.value);
    }

    const updateState = function () {
        dataResult.value = processDataItems();
    }

    const createAppState = function (dataState) {
        take.value = dataState.take;
        skip.value = dataState.skip;
        group.value = dataState.group;
        filter.value = dataState.filter;
        sort.value = dataState.sort;
    }

    const processDataItems = function () {
        checkFilterOperatorForVizientColumns(filter.value);
        var items = process(dataItems, {
            skip: skip.value,
            take: take.value,
            sort: sort.value,
            filter: filter.value,
            group: group.value,
        });

        // Need to convert JSON ISO date/times to Javascript date objects to be handled properly by the Kendo grid.
        items.data.forEach((item) => {
            item['ImportDate'] = convertToDate(item['ImportDate']);
            item['DischargeDate'] = convertToDate(item['DischargeDate']);
            item['AdmitDate'] = convertToDate(item['AdmitDate']);
            item['AuditDateTimeCompleted'] = convertToDate(item['AuditDateTimeCompleted']);
        });
        checkFilterOperatorForVizientColumns(filter.value);

        return items;
    }

    const checkFilterOperatorForVizientColumns = function (filters) {
        // Iterate over the filters array if it's an array
        if (Array.isArray(filters)) {
            filters.forEach(filter => checkFilterOperatorForVizientColumns(filter));
        } else if (filters?.filters) {
            // Process the current filter's nested filters
            filters.filters.forEach(filter => checkFilterOperatorForVizientColumns(filter));
        } else if (filters?.field && filters?.operator) {
            // Process the filter if it has the field and operator properties
            if (vizientColumns.includes(filters.field) && operatorMapping[filters.operator]) {
                filters.operator = operatorMapping[filters.operator];
            }
        }
    }

    const isColumnLocked = function (columnName) {
        return columns.value.filter((ev) => ev.field === columnName)[0].locked;
    }

    const lockChange = function (state, columnName) {
        const columnToLock = columns.value.filter(
            (ev) => ev.field === columnName.field
        )[0];
        columnToLock.locked = state;
    }

    const visibilityChange = function (state, columnName) {
        columns.value.filter((ev) => ev.field === columnName.field)[0].hidden = state;
    }

    const expandChange = function (event) {
        const isExpanded =
            event.dataItem.expanded === undefined
                ? event.dataItem.aggregates
                : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
    }

    const pageChangeHandler = function (event) {
        skip.value = event.page.skip;
        take.value = event.page.take;
    }

    const onColumnsSubmit = function (columnsState) {
        columns.value = columnsState;
    }

    const columnReorder = function (options) {
        columnsReconcile(options);
    }

    const columnResize = function (options) {
        columnsReconcile(options);
    }

    const columnsReconcile = function (options) {
        // When the grid columns are resized or reordered, the hidden columns are removed from the options.columns. (Bug?)
        // So each time, use the full column definition to properly reconcile the new column sizes/order with the full list of columns.
        const prevColumns = ref(columns.value);
        const newColumns = ref(options.columns);
        columns.value = [];

        // For each column...
        prevColumns.value.forEach((prevCol) => {
            var inNewColumns = false;
            // If this column is shown...
            newColumns.value.forEach((newCol) => {
                if (prevCol.field === newCol.field) {
                    // Add it as is to the columns collection.
                    columns.value.push(newCol);
                    inNewColumns = true;
                }
            });
            // If this column is hidden...
            if (!inNewColumns) {
                // Add it as a hidden column to the columns collection.
                prevCol.hidden = true;
                columns.value.push(prevCol);
            }
        });
    }

    const exportExcel = () => {
        var exportData = process(dataItems, {
            skip: 0, // Get all records.
            take: dataItems.total, // Get all records.
            sort: sort.value,
            filter: filter.value,
            group: group.value,
        });
        // The export does not honor the column orderIndex field, so we must reorder the objects in the data using orderIndex.
        columns.value.sort((a, b) => Number(a.orderIndex) - Number(b.orderIndex));
        saveExcel({
            data: exportData.data,
            fileName: `myList_${moment().format('MMDDYYYY')}`,
            sort: sort.value,
            filter: filter.value,
            group: group.value,
            columns: columns.value
        });
    };

    const dataStateChange = function (event) {

        createAppState(event.data);
        dataResult.value = processDataItems();


    }

    const filterChange = function (newDescriptor, event) {

        let isColumnActive = filterGroupByField(event.field, newDescriptor);

        let changedColumn = columns.value.find(function (column) {
            return column.field === event.field;
        });

        if (changedColumn) {
            changedColumn.headerClassName = isColumnActive ? 'gridColumnFiltered' : '';
        }

        filter.value = newDescriptor;
        dataResult.value = processDataItems();

    };

    const convertToDate = function (value) {
        if (value === null) { return null; }
        return new Date(value);
    }

    const currencyDisplay = function (value) {
        if (value === undefined || value === null || value === 0) {
            return ''
        }
        else {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' })
        }
    }

    const percentageDisplay = function (value) {
        if (value === undefined || value === null || value === 0) {
            return ''
        }
        else {
            return value.toLocaleString() + '%'
        }
    }

    // Expose
    defineExpose({ saveGridConfiguration, resetGridConfiguration, exportExcel });

</script>