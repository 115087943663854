<template>
    <div id="homeDiv">
        <div v-if="loading">
            <KLoader :type="'infinite-spinner'" />
        </div>
        <div class="homeHeaderBar k-mb-3">
            <div class="homeHeaderBarLeft buttonSpacers">
                <div v-if="isAuditor">
                    <KDropDownList :style="{ width: '150px' }" :data-items="serviceTypeCategories" :text-field="'text'"
                                   :data-item-key="'id'" :value="selectedCategory" @change="handleCategoryChange">
                    </KDropDownList>
                    <KButton :theme-color="'primary'" @click="startNewAudit()" id="btNewAudit"
                             title="Start new prebill audit based on prioritization">Start New Audit</KButton>
                </div>
            </div>
        </div>
        <div>
            <KTabStrip :selected="tabSelected" @select="onTabSelect" :tabs="tabs" :tab-position="'top'">
                <template v-slot:MyEvaluator>
                    <WidgetDisplay ref="widgetDisplayMyEvaluator" :widgetType="'myevaluator'" loadUserLayout="'false'">
                    </WidgetDisplay>
                </template>
                <template v-slot:Vizient>
                    <div v-if="hasVizientFacility">
                        <WidgetDisplay ref="widgetDisplayVizient" :widgetType="'vizient'" loadUserLayout="'false'">
                        </WidgetDisplay>
                    </div>
                    <div v-if="!hasVizientFacility">
                        Please contact your eValuator Client Success Manager for more information.
                    </div>
                </template>
            </KTabStrip>
        </div>
    </div>
</template>

<style>
    #homeDiv {
        height: 100%;
        min-width: 1200px;
        overflow-x: auto;
    }

    .k-tabstrip .k-animation-container {
        width: 100%;
    }

    .k-tabstrip .k-content.k-active {
        overflow-y: hidden;
    }

    .k-tabstrip .k-tabstrip-items .k-link {
        font-size: 16px;
    }

    .homeHeaderBar {
        display: flex;
    }

    .homeHeaderBarLeft {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: flex-start;
    }

    .homeHeaderBarRight {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: flex-end;
    }
</style>

<script setup>
    import { TabStrip as KTabStrip } from '@progress/kendo-vue-layout';
    import { Button as KButton } from '@progress/kendo-vue-buttons';
    import { DropDownList as KDropDownList } from "@progress/kendo-vue-dropdowns";
    import { fetchAPI } from '../../modules/fetchAPI'
    import { ref, inject, provide, computed, onBeforeMount, onMounted } from 'vue';
    import { useCommonStore } from '../../store/useCommonStore'
    import WidgetDisplay from '../WidgetLibrary/WidgetDisplay.vue';

    // Inject the global config
    const config = inject('config');

    // Data
    const loading = ref(false);
    const serviceTypeCategories = ref([
        { text: "Inpatient", id: "0" },
        { text: "Outpatient", id: "1" },
        { text: "Profee", id: "2" },
    ]);
    const selectedCategory = ref({ text: "Inpatient", id: "0" });

    const commonStore = useCommonStore();
    const eValuatorUri = ref(commonStore.eValuatorUri);
    const contextUser = ref(commonStore.contextUser);
    const isAuditor = ref(commonStore.isAuditor);
    const firstFacility = ref(commonStore.firstFacility);
    const hasVizientFacility = ref(commonStore.hasVizientFacility || commonStore.hasVizientFacility === 'true');
    const infoDialog = inject('$infoDialog');

    const theDiv = ref(null);
    const theTabStrip = ref(null);
    const widgetDisplayMyEvaluator = ref(null);
    const widgetDisplayVizient = ref(null);
    const tabLoaded = [0, 0]; // Boolean values representing whether or not a tab has it's user layout loaded. 

    const useVizientTab = config.useVizientTab; // Vizient feature switch.

    const tabSelected = ref(0);
    const tabs = ref([
        {
            id: 'myEvaluatorTab',
            title: 'My eValuator',
            content: 'MyEvaluator',
        }
    ]);

    // Computed

    // BeforeMount
    onBeforeMount(() => {
        // Vizient feature switch.
        if (useVizientTab) {
            tabs.value.push({
                id: 'vizientTab',
                title: 'Vizient',
                content: 'Vizient'
            });
        }
    });

    // Mounted
    onMounted(() => {
        loading.value = false;
        let categoryToken = localStorage.getItem("categoryToken");
        if (categoryToken != null)
            selectedCategory.value = JSON.parse(categoryToken);

        // Check for a currently selected tab. Select the proper tab.
        let sessionTabSelected = sessionStorage.getItem("HomeLayout_TabSelected");
        tabSelected.value = sessionTabSelected !== null ? Number(sessionTabSelected) : 0;
        // Load the selected tab.
        switch (tabSelected.value) {
            case 0: widgetDisplayMyEvaluator.value?.loadUserLayout(); break;
            case 1: widgetDisplayVizient.value?.loadUserLayout(); break;
            default: break;
        }
        tabLoaded[tabSelected.value] = 1; // Don't reload a tab on a simple tab switch.
    });

    // Methods
    const onTabSelect = (e) => {
        // Close all editing for the current tab.
        switch (tabSelected.value) {
            case 0: widgetDisplayMyEvaluator.value?.doneEditing(); break;
            case 1: widgetDisplayVizient.value?.doneEditing(); break;
        }
        // Set the newly-selected tab.
        tabSelected.value = e.selected;
        // Save the selected tab in the session.
        sessionStorage.setItem("HomeLayout_TabSelected", tabSelected.value);
        // Load the selected tab.
        if (tabLoaded[tabSelected.value] == 0) {
            switch (tabSelected.value) {
                case 0: widgetDisplayMyEvaluator.value?.loadUserLayout(); break;
                case 1: widgetDisplayVizient.value?.loadUserLayout(); break;
            }
            tabLoaded[tabSelected.value] = 1; // Don't reload a tab on a simple tab switch.
        }
    }

    const startNewAudit = async () => {
        loading.value = true;
        const response = await fetchAPI("api/Encounter/NextWorkPoolEncounter?categoryId=" + selectedCategory.value.id + "", "", "POST");
        const workPoolEncounterID = await response.json();
        loading.value = false;

        if (workPoolEncounterID != null && workPoolEncounterID != 0) {
            var myNewEnc = getNewEncounterLink(workPoolEncounterID);
            window.open(myNewEnc);
        } else {
            infoDialog.value.Title = "Start New Audit";
            infoDialog.value.Message = "No available encounters found.";
            infoDialog.value.Show = true;
        }
    }

    const handleCategoryChange = function (event) {
        selectedCategory.value = event.value;
        commonStore.setToken('categoryToken', event.value);
    }

    const getNewEncounterLink = (workPoolEncounterID) => {
        let serviceId = contextUser.value.serviceId ?? 0;
        let clientId = contextUser.value.clientId ?? 0;
        let facilityId = firstFacility.value ?? 0;

        return `${eValuatorUri.value}/SP-${serviceId}/Client-${clientId}/Fac-${facilityId}/StoredProcedure/StartNewAudit?whereFrom=new&workPoolEncounterID=` + workPoolEncounterID;
    }

</script>
